@import "bootstrap.min.css";

html,
body {
	height: 100%;
	font-family: Helvetica,Arial,sans-serif;
}

body {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	padding-top: 40px;
	padding-bottom: 40px;
	background-color: #fff;
}
img.logo {height: 50px;}
.form-signin {
	width: 400px;
	padding: 15px;
	position: absolute;
    top: 10vh;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}
.form-signin .checkbox {
  	font-weight: 400;
}
.form-signin .form-control {
	position: relative;
	box-sizing: border-box;
	height: auto;
	padding: 10px;
	font-size: 16px;
}
.form-signin .form-control:focus {
  	z-index: 2;
}
.form-signin input {
	border-radius: 0;
	margin-bottom: -1px;
}
.form-signin input.first {
	border-top-left-radius: .25rem;
	border-top-right-radius: .25rem;
}
.form-signin input.last {
	margin-bottom: 10px;
	border-bottom-right-radius: .25rem;
	border-bottom-left-radius: .25rem;
}
.generated-signature {
	color: #626d7b !important;
	font-size: 14px;
}
.generated-signature hr {
	clear: both !important;
	margin: 20px 0px !important;
	background: #626d7b !important;
	height: 1px !important;
	width: 25px !important;
	border: none !important;
}
.generated-signature p {
	line-height: 2px !important;
	font-size: 12px !important;
}
.generated-signature a {
	color: #626d7b;
	text-decoration: underline;
}
.generated-signature .name {
	font-size: 14px !important;
	font-weight: 600 !important;
}
.generated-signature .position {margin-bottom: 30px !important;}
.generated-signature .small {
	font-size: 10px !important;
	line-height: 10px !important;
}
.generated-signature .black {color: #000 !important;}
.generated-signature .logo-gen {
	margin-top: 20px !important;
}